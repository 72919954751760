import React from "react"
import Layout from "./src/components/layout"
import { EpisodeProvider, EpisodeConsumer } from "./src/components/context"
import { ThemeProvider, Styled } from "theme-ui"
import theme from "./src/gatsby-plugin-theme-ui/index"
import { SkipNavLink } from "@reach/skip-nav"
import { Container } from "theme-ui"
import "@fortawesome/fontawesome-free/css/all.css"

export const wrapPageElement = ({ element, props }, options) => {
  const episodeSlug = options.episodeSlug ? options.episodeSlug : "show"
  return (
    <ThemeProvider theme={theme}>
      <EpisodeProvider>
        <Styled.root>
          <SkipNavLink />
          <Layout {...props}>
            {props.location.pathname.includes(episodeSlug) ||
            props.location.pathname === "/" ? (
              <EpisodeConsumer>
                {(context) => {
                  return (
                    <div
                      style={{
                        zIndex: 10,
                        position: "fixed",
                        width: "100%",
                        color: "text",
                        borderTop: "2px solid",
                        borderColor: "#232B3B",
                        backgroundColor: "#1A2232",
                        height: "auto",
                        bottom: 0,
                        left: 0,
                        display: "flex",

                        alignItems: "center",
                      }}
                      className="player"
                    >
                      {context.state && (
                        <Container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: JSON.parse(
                              context.state.anchorEmbedLink.anchorEmbedLink
                            ).content[0].content[0].value,
                          }}
                        ></Container>
                      )}
                    </div>
                  )
                }}
              </EpisodeConsumer>
            ) : null}
            {element}
          </Layout>
        </Styled.root>
      </EpisodeProvider>
    </ThemeProvider>
  )
}
