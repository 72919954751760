/** @jsx jsx */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { jsx, Flex } from "theme-ui"
import { EpisodeConsumer } from "./context"
import { FaPlay as PlayIcon } from "react-icons/fa"
import { MdMenu as MenuIcon, MdClose as CloseMenuIcon } from "react-icons/md"
import onClickOutside from "react-onclickoutside"
import config from "../lib/config"
import Link from "./link"
import Bars from "./bars"

function Navigation() {
  const seasons = [0, 1, 2, 3, 4]
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleMenu = () => setIsOpen(!isOpen)
  Navigation.handleClickOutside = () => setIsOpen(false)
  const twoDigits = n => (n.toString().length < 2 ? `0${n}` : n)
  const [selectedSeason, setSelectedSeason] = React.useState(
    config.podcastSeason
  )

  const Logo = () => (
    <>
      <Link to="/">
        <h1 sx={{ fontSize: 6, color: "primary", mb: 0 }}>
          {data.site.siteMetadata.title
            ? data.site.siteMetadata.title
            : "Podcast Name"}
        </h1>
      </Link>
      {config.podcastSeason && (
        <select
          style={{
            fontWeight: "700",
            marginTop: "10px",
            textTransform: "uppercase",
            backgroundColor: "transparent",
            color: "orange",
            border: "none",
            width: "100%",
          }}
          value={selectedSeason}
          onChange={event => setSelectedSeason(parseInt(event.target.value))}
        >
          {seasons.map(x => {
            return (
              <option
                key={x}
                defaultValue={3}
                value={x}
                sx={{
                  textTransform: "uppercase",
                  mt: 2,
                  mb: 0,
                  fontWeight: 400,
                  fontSize: 0,
                  opacity: 0.6,
                }}
              >
                {`Season ${twoDigits(x)}`}
              </option>
            )
          })}
        </select>
      )}
    </>
  )

  const data = useStaticQuery(graphql`
    query navQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulEpisode(filter: { node_locale: { eq: "en-US" } }) {
        totalCount
        nodes {
          id
          name
          anchorEmbedLink {
            anchorEmbedLink
          }
          showNotes {
            childContentfulShowNotesTextShowNotesRichTextNode {
              json
            }
          }
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          image {
            fluid(maxWidth: 720) {
              ...GatsbyContentfulFluid
            }
          }
          johnRating
          mattRating
          createdAt
          publicationDate
          shortDescription
          season
          episodeNumber
          fields {
            slug
          }
        }
      }
    }
  `)

  const sortedEpisodes = data.allContentfulEpisode.nodes
    .sort((a, b) =>
      a.createdAt > b.createdAt && a.episodeNumber > b.episodeNumber ? -1 : 1
    )
    .filter(x => x.season === parseInt(selectedSeason))

  return (
    <EpisodeConsumer>
      {context => (
        <>
          <Flex
            sx={{
              variant: "header.logo.container",
            }}
          >
            <Flex
              sx={{
                variant: "header.logo",
              }}
            >
              <Logo />
            </Flex>
            <button
              sx={{
                position: "relative",
                zIndex: 998,
                display: "flex",
                p: 3,
                backgroundColor: "background",
                color: "text",
                borderColor: "backgroundLighten20",
                fontSize: 5,
              }}
              onClick={toggleMenu}
              aria-controls="menu"
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : "false"}
            >
              {isOpen ? <CloseMenuIcon /> : <MenuIcon />}
            </button>
          </Flex>
          <nav
            className="episodes_list"
            sx={{
              variant: "navigation.episodes",
              transform: [`translateX(${isOpen ? "0" : "-100%"})`, "none"],
              transition: "300ms cubic-bezier(1, 0, 0, 1)",
            }}
          >
            <div sx={{ ml: 6, pb: 4 }}>
              <Logo />
            </div>
            <ul id="menu" role="menu" sx={{ pb: 14 }}>
              {sortedEpisodes.map(episode => {
                return (
                  <li role="none" key={episode.id}>
                    {episode.id === context.state.id && <Bars />}
                    <Link
                      role="menuitem"
                      activeClassName="active"
                      to={episode.fields.slug}
                    >
                      <h4>{episode.name}</h4>
                    </Link>
                    {episode.id !== context.state.id && (
                      <button
                        tabIndex="-1"
                        onClick={() => context.setCurrentPlaying(episode)}
                      >
                        <PlayIcon aria-hidden="true" />
                      </button>
                    )}
                  </li>
                )
              })}
              {sortedEpisodes.length === 0 && (
                <li>New episodes coming soon!</li>
              )}
            </ul>
          </nav>
        </>
      )}
    </EpisodeConsumer>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => Navigation.handleClickOutside,
}

export default onClickOutside(Navigation, clickOutsideConfig)
