import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"

const EpisodeContext = React.createContext()

export function EpisodeProvider(props) {
  const data = useStaticQuery(graphql`
    {
      allContentfulEpisode(filter: { node_locale: { eq: "en-US" } }) {
        totalCount
        nodes {
          id
          anchorEmbedLink {
            anchorEmbedLink
          }
          showNotes {
            childContentfulShowNotesTextShowNotesRichTextNode {
              json
            }
          }
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          image {
            fluid(maxWidth: 720) {
              ...GatsbyContentfulFluid
            }
          }
          name
          johnRating
          mattRating
          createdAt
          publicationDate
          shortDescription
          season
          episodeNumber
          fields {
            slug
          }
        }
      }
    }
  `)

  const sortedEpisodes = data.allContentfulEpisode.nodes.sort((a, b) =>
    a.createdAt > b.createdAt && a.episodeNumber > b.episodeNumber ? -1 : 1
  )

  const [currentPlaying, setCurrentPlaying] = React.useState(sortedEpisodes[0])

  return (
    <EpisodeContext.Provider
      value={{
        state: currentPlaying,
        setCurrentPlaying,
      }}
      {...props}
    />
  )
}

export class EpisodeConsumer extends Component {
  render() {
    return (
      <EpisodeContext.Consumer>{this.props.children}</EpisodeContext.Consumer>
    )
  }
}
