export default {
  podcastSeason: "4",
  headerImageHeight: [300, 400],
  anchorUrl: "https://anchor.fm/cinemamenpodcast",
  spotifyUrl: "https://open.spotify.com/show/6VzjvfPSBKcj9DBAslUNox",
  applePodcastsUrl:
    "https://podcasts.apple.com/us/podcast/the-cinemamen-podcast/id1496159381?uo=4",
  googlePodcastsUrl:
    "https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy9kYjc5YmUwL3BvZGNhc3QvcnNz",
}
